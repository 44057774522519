<template>
  <div
    id="seating_chart_price"
    class="seating_chart_price"
  >
    <div class="price">
      <span>Price:</span> <CurrencyDropdown @currency-emit="updateCurrency" />
    </div>
    <RangeSlider
      :min-price="minPackagePrice"
      :max-price="maxPackagePrice"
      :currency="currency"
      :rate="rate"
    />
  </div>
</template>
<script>
import { CurrencyDropdown } from '../default/currency_convert'
import RangeSlider from './range_slider'

export default {
  components:  {
    CurrencyDropdown,
    RangeSlider
  },

  data() {
    return {
      currency: 'USD',
      rate: 1.0
    }
  },

  computed: {
    minPackagePrice() {
      let productItem = document.querySelector('.product-item')
      if(productItem && productItem.getAttribute('data-price')){
        // packages

        let minPrice = parseInt(productItem.getAttribute('data-price'))

        $('.product-item').each(function () {
          let price = parseInt($(this).attr('data-price'))

          if(price < minPrice) {
            minPrice = price
          }
        })
        return minPrice
      } else if (productItem) {
        // subpackages
        // let productItem = document.querySelector('.product-item')
        let minPrice = parseInt(productItem.querySelector('.headline_row').getAttribute('data-price'))

        $('.product-item .headline_row').each(function () {
          let price = parseInt($(this).attr('data-price'))

          if(price < minPrice) {
            minPrice = price
          }
        })
        return minPrice
      } else {
        return 0
      }
    },

    maxPackagePrice() {
      let productItem = document.querySelector('.product-item')
      if(productItem && productItem.getAttribute('data-price')){
        // packages
        let maxPrice = parseInt(document.querySelector('.product-item').getAttribute('data-price'))

        $('.product-item').each(function () {
          let price = parseInt($(this).attr('data-price'))

          if(price > maxPrice) {
            maxPrice = price
          }
        })
        return maxPrice
      } else if (productItem) {
      // subpackages
        // let productItem = document.querySelector('.product-item')
        let maxPrice = parseInt(productItem.querySelector('.headline_row').getAttribute('data-price'))

        $('.product-item .headline_row').each(function () {
          let price = parseInt($(this).attr('data-price'))

          if(price > maxPrice) {
            maxPrice = price
          }
        })
        return maxPrice
      } else {
        return 0
      }
    }
  },

  methods: {
    updateCurrency(errors, currency, rate) {
      if (currency && rate) {
        this.currency = currency
        this.rate = rate
      }
    }
  }
}
</script>
