<template>
  <div
    id="range-slider"
    class="range-slider"
  >
    <label class="min-slider">{{ formattedMinValue }}</label>
    <div
      v-if="minPrice === maxPrice"
      class="slider"
    >
      <input
        v-model="sliderMin"
        type="range"
        :min="minPrice"
        :max="maxPrice"
        step="1"
        class="slider__min-slider equal-min-slider"
      >
      <div class="slider-fill equal-slider-fill" />
      <input
        v-model="sliderMax"
        type="range"
        :min="minPrice"
        :max="maxPrice"
        step="1"
        class="slider__max-slider equal-max-slider"
      >
    </div>
    <div
      v-else
      class="slider"
    >
      <input
        v-model="sliderMin"
        type="range"
        :min="minPrice"
        :max="maxPrice"
        step="1"
        class="slider__min-slider"
      >
      <div class="slider-fill" />
      <input
        v-model="sliderMax"
        type="range"
        :min="minPrice"
        :max="maxPrice"
        step="1"
        class="slider__max-slider"
      >
    </div>

    <label class="max-slider">{{ formattedMaxValue }}</label>
  </div>
</template>
<script>
import { CurrencyApi } from '../default/currency_convert'

export default {

  props: {
    minPrice: {
      default: 0,
      required: true,
      type: Number
    },
    maxPrice: {
      default: 0,
      required: true,
      type: Number
    },
    currency: {
      default: 'USD',
      required: true,
      type: String
    },
    rate: {
      default: 1.0,
      required: true,
      type: Number
    }
  },

  data() {
    return {
      minValue: this.minPrice,
      maxValue: this.maxPrice,
      formattedMinValue: 0,
      formattedMaxValue: 0

    }
  },

  computed: {
    sliderMin: {
      get: function () {
        var val = parseInt(this.minValue)
        this.formattedMinValue =  CurrencyApi.formatAmountNoCents(parseInt(val * this.rate), this.currency)  // eslint-disable-line vue/no-side-effects-in-computed-properties
        return val
      },
      set: function (val) {
        val = parseInt(val)
        if (val > this.maxValue) {
          this.maxValue = val
        }
        this.minValue = val
      }
    },

    sliderMax: {
      get: function () {
        var val = parseInt(this.maxValue)
        this.formattedMaxValue = CurrencyApi.formatAmountNoCents(parseInt(val * this.rate), this.currency)  // eslint-disable-line vue/no-side-effects-in-computed-properties
        return val
      },
      set: function (val) {
        val = parseInt(val)
        if (val < this.minValue) {
          this.minValue = val
        }
        this.maxValue = val
      }
    }
  }
}
</script>
